import React, { useState, useEffect } from 'react';
import { ChevronDown, Mail, MapPin } from 'lucide-react';
import jsonp from 'jsonp';
import AnimatedTargetSelectionEngine from './targetSelectionEngine/AnimatedTargetSelectionEngine';
import './App.css';

const TAGLINE = "Target intelligence for tomorrow’s medicines";

const FullPageSection = ({ children, className }) => (
  <section className={`full-page-section ${className}`}>
    <div className="max-w-6xl mx-auto px-4 w-full">
      {children}
    </div>
  </section>
);

const ContentBox = ({ title, children, titleColor = "text-blue" }) => (
  <div className="content-box">
    <h2 className={`text-3xl font-semibold ${titleColor} mb-4`}>{title}</h2>
    {children}
  </div>
);

const NavisBioLanding = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };


  return (
    <div className="font-sans text-dark-blue">
      <FullPageSection className="color-burgundy">
        <div className="relative text-center">
          <div className="relative z-10">
            <h1 className="text-6xl font-bold text-white mb-6 transition-all duration-500 ease-out"
                style={{ transform: `translateY(-${scrollPosition * 0.2}px)` }}>
              Navis Bio
            </h1>
            <p className="text-2xl text-white mt-4 max-w-2xl mx-auto transition-all duration-500 ease-out"
               style={{ transform: `translateY(-${scrollPosition * 0.1}px)` }}>
              {TAGLINE}
            </p>
            <button onClick={scrollToContent} className="mt-12 text-white hover:text-[#92CBEA] transition-colors duration-300">
              <ChevronDown size={32} />
            </button>
          </div>
        </div>
      </FullPageSection>

      <FullPageSection className="bg-pale-blue">
        <div className="space-y-8 mt-16 mb-16">
          <ContentBox title="Our Company">
            <p className="text-dark-blue leading-relaxed">
            We are building a target intelligence platform for biopharma, aiming to become the primary source of information for decisions on therapeutic targets, covering the whole spectrum from research, over clinical trial design, to business development. 
            </p>
          </ContentBox>
          <ContentBox title="Our Offering">
            <p className="text-dark-blue leading-relaxed mb-4">
            We provide knowledge, data, and tools to help scientists and executives decide whether a (molecular) target is worth pursuing or not. Our value propositions include:
            </p>
            <div className="space-y-4 mt-6 flex flex-col items-start">
            {[
              "Rapid assessment of target trends at conferences",
              "Find the right trade-off between an overcrowded target space and unvalidated targets",
              "Interrogate existing patient data to valide assumptions on target prevalence",
              "Competitive insights, who else is working on the same target?",
            ].map((item, index) => (
              <div key={index} className="offering-box">
                <p className="offering-text">{item}</p>
              </div>
            ))}
          </div>

          </ContentBox>
          <ContentBox title="Team">
            <p className="text-[#44546A] leading-relaxed">

              We have extensive experience in leading corporations in biopharma, consulting, and IT, and a successful history of generating value from data assets for drug discovery, indication prioritization, business development, and M&A. As generative AI is disrupting the way we acquire and process information, we decided to found Navis Bio to build the first comprehensive platform for target intelligence, by aggregating, crosslinking, and making sense of biomedical data from diverse sources and modalities.
            </p>
            <div className="mt-6">
              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6">
                <div className="flex flex-col items-center">
                  <img src="/Nives.jpg" alt="Nives Rombini" className="w-32 h-32 rounded-full mb-2" />
                  <span>Nives Rombini</span>
                  <a href="https://www.linkedin.com/in/nives-rombini/" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-600 hover:underline">
                    <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                  </a>
                </div>
                <div className="flex flex-col items-center">
                  <img src="/Jonas.jpg" alt="Jonas Walheim" className="w-32 h-32 rounded-full mb-2" />
                  <span>Jonas Walheim, PhD</span>
                  <a href="https://www.linkedin.com/in/jonas-walheim-441b0284/" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-600 hover:underline">
                    <img src="/LI-In.png" alt="LinkedIn" className="w-5 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </ContentBox>
          <ContentBox title="" titleColor="text-[#2656BB]">
            <p className="mb-4 text-[#44546A]">
            </p>
            <div className="flex justify-center">
              <a
                href="mailto:info@navis-bio.com"
                className="w-[30%] text-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-[#2656BB] hover:bg-[#1E4A9D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2656BB] transition duration-150 ease-in-out"
              >
                Get in touch
              </a>
            </div>
          </ContentBox>
        </div>
      </FullPageSection>

      <footer className="bg-dark-blue text-white py-16">
  <div className="max-w-6xl mx-auto px-4">
    <div className="footer-content">
      <div>
        <h2 className="text-2xl font-bold mb-4">Navis Bio</h2>
        <p>{TAGLINE}</p>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
        <div className="flex items-center mb-2">
          <Mail size={20} className="mr-2" />
          <span>info@navis-bio.com</span>
        </div>
          <div className="flex items-center">
  <MapPin size={20} className="mr-2" />
  <span>Dover DE, United States<br />Zurich, Switzerland</span>
</div>
    

      </div>
    </div>
    <div className="mt-12 pt-8 border-t border-[#91C2E9]/30 text-center">
      <p>© 2024 Navis Bio Inc., All rights reserved.</p>
    </div>
  </div>
</footer>

    </div>
  );
};

export default NavisBioLanding;