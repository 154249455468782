export const Button = ({ className, ...props }) => (
  <button className={`px-4 py-2 rounded ${className}`} {...props} />
);

export const Switch = ({ checked, onCheckedChange }) => (
  <input type="checkbox" checked={checked} onChange={(e) => onCheckedChange(e.target.checked)} />
);

export const Select = ({ children, ...props }) => (
  <select {...props}>{children}</select>
);

export const SelectContent = ({ children }) => <>{children}</>;

export const SelectItem = ({ value, children }) => (
  <option value={value}>{children}</option>
);

export const SelectTrigger = ({ children }) => <>{children}</>;

export const SelectValue = ({ placeholder }) => <>{placeholder}</>;