import React, { useState, useEffect, useRef } from 'react';

const FeedbackElement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const feedbackRef = useRef(null);

  const toggleFeedback = () => {
    setIsOpen(!isOpen);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const submitFeedback = () => {
    const emailSubject = encodeURIComponent('[AbstractViewer] Feedback');
    const emailBody = encodeURIComponent(`${feedback}`);
    window.location.href = `mailto:info@navis-bio.com?subject=${emailSubject}&body=${emailBody}`;
    setFeedback('');
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (feedbackRef.current && !feedbackRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="fixed right-4 top-1/2 transform -translate-y-1/2">
      {isOpen ? (
        <div ref={feedbackRef} className="abstracts-viewer-feedback-form p-4 rounded-r-lg shadow-lg">
          <textarea
            className="abstracts-viewer-feedback-textarea w-full h-24 p-2 border rounded mb-2"
            placeholder="Please tell us anything you like or do not like, we will learn from your experience. Thank you for taking the time!"
            value={feedback}
            onChange={handleFeedbackChange}
          ></textarea>
          <button className="abstracts-viewer-submit-feedback-button px-4 py-2 rounded" onClick={submitFeedback}>
            Submit Feedback
          </button>
        </div>
      ) : (
        <button
          className="abstracts-viewer-feedback-button px-1 py-1 rounded-t-lg transform -rotate-90 origin-right w-40 h-6 flex items-center justify-center"
          onClick={toggleFeedback}
        >
          Feedback
        </button>
      )}
    </div>
  );
};

export default FeedbackElement;
