import React, { useState, useEffect } from 'react';
import DataTable from './DataTable';
import './styles.css';

const DataVisualizer = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});

  const columnMapping = {
    'Abstract_Number': 'Abstract Number',
    'Abstract_Title': 'Abstract Title',
    'Indication': 'Indication',
    'Phase': 'Phase',
    'Genes_incl_biomarkers': 'Genes (incl. biomarkers)',
    'Targets': 'Targets',
    'Modality': 'Modality',
    'Therapeutic_Agents': 'Therapeutic Agents'
  };

  const columns = Object.keys(columnMapping);

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    const newFilteredData = data.filter(row => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        return row[key] && row[key].toString().toLowerCase().includes(value.toLowerCase());
      });
    });
    setFilteredData(newFilteredData);
  }, [data, filters]);

  const handleFilterChange = (column, value) => {
    setFilters(prev => ({ ...prev, [column]: value }));
  };

  if (data.length === 0) {
    return <div>Loading data...</div>;
  }

  return (
    <div className="data-visualizer">
      <div className="filter-bar">
        {columns.map(column => (
          <div key={column} className="filter-item">
            <label htmlFor={`filter-${column}`}>{columnMapping[column]}</label>
            <input
              id={`filter-${column}`}
              type="text"
              placeholder={`Filter ${columnMapping[column]}`}
              value={filters[column] || ''}
              onChange={(e) => handleFilterChange(column, e.target.value)}
            />
          </div>
        ))}
      </div>
      <DataTable 
        data={filteredData} 
        columns={columns}
        columnMapping={columnMapping}
      />
    </div>
  );
};

export default DataVisualizer;
