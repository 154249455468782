import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TargetPlots = ({ data }) => {
  const [selectedIndication, setSelectedIndication] = useState('All');
  const [selectedPhase, setSelectedPhase] = useState('All');
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    processTargetMentions();
  }, [data, selectedIndication, selectedPhase]);

  const processTargetMentions = () => {
    const targetPhases = {};
    data.forEach(item => {
      const normalizedPhaseForColor = normalizePhaseForColor(item.Phase);
      const normalizedPhaseForSelector = normalizePhaseSelector(item.Phase);
      if ((selectedIndication === 'All' || item.Indication === selectedIndication) &&
          (selectedPhase === 'All' || normalizedPhaseForSelector === selectedPhase)) {
        let targets = [];
        try {
          // Split the targets string by comma and trim each target
          targets = item.Targets.split(',').map(target => target.trim());
        } catch (error) {
          console.error(`Error parsing Targets for item: ${item['Abstract Number']}`, error);
        }
        targets.forEach(target => {
          if (!targetPhases[target]) {
            targetPhases[target] = { 'Preclinical': 0, 'Phase I': 0, 'Phase II': 0, 'Phase III': 0, 'Other': 0 };
          }
          targetPhases[target][normalizedPhaseForColor]++;
        });
      }
    });

    const sortedTargets = Object.entries(targetPhases)
      .sort((a, b) => Object.values(b[1]).reduce((sum, count) => sum + count, 0) - 
                      Object.values(a[1]).reduce((sum, count) => sum + count, 0))
      .slice(0, 20);

    const labels = sortedTargets.map(([target]) => target);
    const datasets = [
      {
        label: 'Preclinical',
        data: sortedTargets.map(([, phases]) => phases['Preclinical']),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
      {
        label: 'Phase I',
        data: sortedTargets.map(([, phases]) => phases['Phase I']),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
      },
      {
        label: 'Phase II',
        data: sortedTargets.map(([, phases]) => phases['Phase II']),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Phase III',
        data: sortedTargets.map(([, phases]) => phases['Phase III']),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Other',
        data: sortedTargets.map(([, phases]) => phases['Other']),
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      }
    ];

    setChartData({ labels, datasets });
  };

  const handleIndicationChange = (event) => {
    setSelectedIndication(event.target.value);
  };

  const handlePhaseChange = (event) => {
    setSelectedPhase(event.target.value);
  };

  const options = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true }
    },
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Top 20 Target Mentions by Phase',
      },
    },
  };

  return (
    <div className="target-mentions-visualizer">
      <div className="selector-container">
        <div className="selector">
          <label htmlFor="indication-select">Select Indication:</label>
          <select id="indication-select" value={selectedIndication} onChange={handleIndicationChange}>
            <option value="All">All Indications</option>
            {Array.from(new Set(data.map(item => item.Indication))).map(indication => (
              <option key={indication} value={indication}>{indication}</option>
            ))}
          </select>
        </div>
        <div className="selector">
          <label htmlFor="phase-select">Select Phase:</label>
          <select id="phase-select" value={selectedPhase} onChange={handlePhaseChange}>
            <option value="All">All Phases</option>
            {Array.from(new Set(data.map(item => normalizePhaseSelector(item.Phase))))
              .sort((a, b) => a.localeCompare(b))
              .map(phase => (
                <option key={phase} value={phase}>{phase}</option>
              ))
            }
          </select>
        </div>
      </div>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default TargetPlots;

const normalizePhaseSelector = (phase) => {
  if (!phase) return 'Not Specified';
  const lowerPhase = phase.toLowerCase();
  if (lowerPhase.includes('preclinical')) return 'Preclinical';
  if (lowerPhase.includes('phase 1/phase 2a') || lowerPhase.includes('phase 1b/2')) return 'Phase 1/Phase 2';
  if (lowerPhase.includes('phase 2/3')) return 'Phase 2/Phase 3';
  if (lowerPhase.includes('none') || lowerPhase.includes('unknown') || lowerPhase.includes('not specified')) return 'Not Specified';
  return phase;
};

const normalizePhaseForColor = (phase) => {
  if (!phase) return 'Other';
  const lowerPhase = phase.toLowerCase();
  if (lowerPhase.includes('preclinical')) return 'Preclinical';
  if (lowerPhase.includes('phase 2/phase 3') || lowerPhase.includes('phase 2/3')) return 'Phase III';
  if (lowerPhase.includes('phase 1/phase 2') || lowerPhase.includes('phase 1/2')) return 'Phase II';
  if (lowerPhase.includes('phase iii') || lowerPhase.includes('phase 3')) return 'Phase III';
  if (lowerPhase.includes('phase ii') || lowerPhase.includes('phase 2')) return 'Phase II';
  if (lowerPhase.includes('phase i') || lowerPhase.includes('phase 1')) return 'Phase I';
  return 'Other';
};
