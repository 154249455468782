import React, { useState, useEffect } from 'react';
import DataVisualizer from './DataVisualizer';
import TargetPlots from './TargetPlots';
import './abstractsViewer.css';
import FeedbackElement from './feedbackElement';

const AbstractsViewer = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('data');

  const Tab = ({ label, isActive, onClick }) => (
    <button
      className={`tab ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );

  useEffect(() => {
    console.log('Fetching data...');
    const url = 'https://fic67ysvr6.execute-api.eu-north-1.amazonaws.com/prod';
    console.log('Fetching from URL:', url);
    fetch(url)
      .then(response => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(jsonData => {
        console.log('Received JSON data:', jsonData);
        if (jsonData.length === 0) {
          throw new Error('No data received from API');
        }
        console.log('First 5 rows of data:', jsonData.slice(0, 5));
        setData(jsonData);
      })
      .catch(error => {
        console.error('Error fetching or parsing data:', error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="App">
      <header style={{
        backgroundColor: '#3f51b5',
        color: 'white',
        padding: '20px',
        marginBottom: '20px',
        textAlign: 'center'
      }}>
        <h1 style={{
          margin: 0,
          fontSize: '24px',
          marginBottom: '10px'
        }}>ESMO Abstracts Visualizer</h1>
        <div style={{
          fontSize: '12px',
          lineHeight: '1.4'
        }}>
          Information derived from abstracts on{' '}
          <a 
            href="https://cslide.ctimeetingtech.com/esmo2024/attendee/confcal_2/presentation" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            ESMO 2024 website
          </a>
          .<br />
          Follow link for full abstracts and original content.
        </div>
      </header>
      {data.length > 0 ? (
        <>
          <div className="tabs">
            <Tab 
              label="Abstracts Summary" 
              isActive={activeTab === 'data'} 
              onClick={() => setActiveTab('data')}
            />
            <Tab 
              label="Gene Targets" 
              isActive={activeTab === 'targets'} 
              onClick={() => setActiveTab('targets')}
            />
          </div>
          {activeTab === 'data' && <DataVisualizer data={data} />}
          {activeTab === 'targets' && <TargetPlots data={data} />}
        </>
      ) : (
        <div>Loading data...</div>
      )}
      <FeedbackElement />
    </div>
  );
};

export default AbstractsViewer;